<template>
  <base-modal-view :title="modalTitle" :message="modalMessaage" @cancel="cancelClicked">
    <p class="text-field-title no-margin">VIN Number</p>
    <input
      v-model="vin"
      class="text-field margin-top-025"
      type="text"
      placeholder="Enter your VIN"
      v-if="!requestStatus"
    />
    <p class="margin-left-auto label margin-top-025" v-html="vinMessage" v-if="!requestStatus"></p>
    <primary-button
      v-on:click="submitClicked"
      class="margin-top"
      title="Request"
      :disabled="remainingCharacters() != 0"
      v-if="!requestStatus && !requestLoading"
    />
    <p
      v-if="requestMessage"
      :class="[requestStatus ? 'text-success' : 'text-danger']"
      class="text-center"
      v-html="requestMessage"
    ></p>
    <div class="spinner-border mx-auto" role="status" v-if="requestLoading">
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-if="requestStatus && !loading" class="container">
      <div class="row">
        <div class="col text-center">
          <primary-button
            @click="requestPPSRPDF()"
            :disabled="requestLoading"
            title="Download PDF"
            style="margin-left: auto; margin-right: auto"
          />
        </div>
        <div class="col text-center">
          <primary-button
            @click="submitClicked($event, true)"
            :disabled="requestLoading"
            title="Request a new PPSR"
            style="margin-left: auto; margin-right: auto"
          />
        </div>
      </div>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { requestFormlessPPSR, requestFormlessPPSRPDF } from "@/api/PPSR.js";

export default {
  name: "PpsrModal",
  components: { PrimaryButton, BaseModalView },
  data: function () {
    return {
      vin: "",
      requestStatus: null,
      requestLoading: false,
      requestPDFStatus: false,
      requestMessage: "",
      requestDate: null,
      requestDateUpdated: null,
      ppsrId: null
    };
  },
  methods: {
    remainingCharacters: function () {
      return 17 - this.vin.length;
    },
    submitClicked: function (event, newRequest = false) {
      this.requestLoading = true;
      this.requestStatus = null;
      this.requestMessage = "";
      requestFormlessPPSR(this.vin, newRequest)
        .then(response => {
          this.requestStatus = true;
          let data = response.data;
          this.ppsrId = data?.id;
          this.requestDate = data.request_date;
          this.requestDateUpdated = data.request_date_updated;
          if (data.request_date == data.request_date_updated) {
            let date = this.requestDate;
            let dateUpdated = this.requestDateUpdated;
            // Convert to date object
            if (date) {
              date = Date(date);
            }
            if (dateUpdated) {
              dateUpdated = Date(dateUpdated);
            }
            this.requestMessage = `A PPSR for this VIN HAS ALREADY been requested, download the PPSR PDF below, or request a new one. <br /><br /> <b>Request date:</b> <br /> ${date}`;
            if (date != dateUpdated) {
              this.requestMessage += ` <br /> <b>Request date updated:</b> <br /> ${dateUpdated}`;
            }
          } else {
            this.requestMessage = `Successfully requested a PPSR, download the PPSR PDF below, or request a new one.`;
          }
          // this.modalCancelled();
        })
        .catch(error => {
          this.requestStatus = false;
          this.requestMessage = error.response?.data?.detail;
          // this.modalCancelled();
        })
        .finally(() => {
          this.requestLoading = false;
        });
    },
    requestPPSRPDF: function () {
      this.requestLoading = true;
      requestFormlessPPSRPDF(this.ppsrId);
      setTimeout(() => {
        requestFormlessPPSRPDF(this.ppsrId)
          .then(response => {
            this.requestPDFStatus = true;
            let pdf = response.data?.pdf_download;
            this.requestMessage = `PDF is being downloaded.`;

            let filename = "ppsr_pdf";
            // this.modalCancelled();
            pdf = `data:application/pdf;base64,${response.data?.pdf_download}`;

            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            var newBlob = new Blob([pdf], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob);
              return;
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            // const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement("a");
            link.href = pdf;
            link.download = filename + ".pdf";
            link.click();
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              // window.URL.revokeObjectURL(data);
            }, 100);
          })
          .catch(() => {
            this.requestPDFStatus = false;
            this.requestMessage = "PPSR PDF has generated, click Download PDF to retrieve it now.";
            // this.modalCancelled();
          })
          .finally(() => {
            this.requestLoading = false;
          });
      }, 2000);
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  computed: {
    vinMessage: function () {
      if (this.remainingCharacters() > 0) {
        return `<span class="text-primary">${this.remainingCharacters()} more characters required</span>`;
      } else if (this.remainingCharacters() < 0) {
        return `<span class="text-danger">Too many characters!</span>`;
      } else {
        return `<span class="text-success">Acceptable VIN</span>`;
      }
    },
    modalTitle: function () {
      let title = "Request PPSR";
      return title;
    },
    modalMessaage: function () {
      let msg =
        "Insert the VIN Number for the vehicle which you would like to query. The VIN Number must be 17 digits and cannot contain the characters 'I', 'O', and 'Q'";
      if (this.requestStatus) {
        msg = "";
      }
      return msg;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/_variables.scss";
@import "@/scss/v2/base.scss";
</style>
