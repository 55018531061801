<template>
  <base-modal-view title="Select Marketing Plan" @cancel="cancelClicked">
    <h6 v-if="marketingPlans.length <= 0">There don't seem to be any pending marketing plans.</h6>
    <table v-if="marketingPlans.length > 0" class="results-table">
      <tr>
        <th>Title</th>
      </tr>
      <tr
        v-for="marketingPlan in marketingPlans"
        v-bind:key="marketingPlan.id"
        class="data-row selectable"
        v-on:click="selectedMarketingPlans(marketingPlan)"
      >
        <td>{{ marketingPlan.title }}</td>
      </tr>
    </table>
  </base-modal-view>
</template>

<script>
import BaseModalView from "./BaseModalView.vue";
import { fetchPendingMarketingPlans } from "../../../api/Marketing.js";
import { mapActions } from "vuex";

export default {
  name: "MarketingListModal",
  components: { BaseModalView },
  data: function () {
    return {
      marketingPlans: []
    };
  },
  methods: {
    cancelClicked: function () {
      this.$emit("cancel");
    },
    selectedMarketingPlans(marketingPlan) {
      this.$emit("submit", marketingPlan.id);
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  mounted: function () {
    fetchPendingMarketingPlans()
      .then(response => {
        this.marketingPlans = response;
      })
      .catch(error => {
        this.addError(error);
      });
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
