<template>
  <base-modal-view
    message='Are you sure you want to archive all active appraisals? You wont be able to receive offers from the app on these appraisals anymore. To confirm, please type "Archive"'
    title="Archive All Pending Appraisals"
    @cancel="cancelClicked"
  >
    <p class="text-field-title margin-top margin-bottom-025">Dealership to archive</p>
    <select class="dropdown-field" v-model="dealership" name="dealership">
      <option value="">-- Select Dealership to Archive --</option>
      <option v-for="dealership in dealerships" :value="dealership.id" v-bind:key="dealership.id">
        {{ dealership.first_name }}
      </option>
    </select>
    <input
      v-model="textCheck"
      class="text-field margin-top-075"
      type="text"
      placeholder='Enter "Archive"'
      name=""
      id=""
    />
    <primary-button :disabled="!checkSucceeds()" v-on:click="submitClicked" class="margin-top-075" title="Archive" />
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { mapState } from "vuex";

export default {
  name: "ArchiveAllPendingModal",
  components: { PrimaryButton, BaseModalView },
  data: function () {
    return {
      textCheck: "",
      dealership: ""
    };
  },
  methods: {
    checkSucceeds: function () {
      return this.textCheck == "Archive" && this.dealership;
    },
    submitClicked: function () {
      if (this.checkSucceeds()) {
        this.$emit("submit", this.dealership);
      }
    },
    cancelClicked: function () {
      this.$emit("cancel");
    }
  },
  computed: {
    ...mapState({
      dealerships: state => state.dealershipStore.dealerships
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
