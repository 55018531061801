<template>
  <base-modal-view title="New Appraisal" @cancel="cancelClicked">
    <form class="flex-column" @submit.prevent="submitClicked">
      <div>
        <p class="label no-margin margin-top">Car Sighted {{ !currentDealership.is_buyers_account ? "*" : "" }}</p>
        <div class="flex-row margin-top-05">
          <input
            :required="!currentDealership.is_buyers_account"
            class="margin-right-05 radio-field"
            type="radio"
            name="sighted"
            id="sighted"
            value="Sighted"
            v-model="dict.source"
          />
          <label class="body-2 vertical-flex-center" for="sighted">Sighted</label>
        </div>
        <div class="flex-row margin-top-05">
          <input
            :required="!currentDealership.is_buyers_account"
            class="margin-right-05 radio-field"
            type="radio"
            name="sighted"
            id="not_sighted"
            value="Not Sighted"
            v-model="dict.source"
          />
          <label for="not_sighted" class="body-2 vertical-flex-center">Not Sighted</label>
        </div>
      </div>
      <div class="flex-column">
        <p class="h5 no-margin margin-bottom-05 margin-top-2">Customer Details</p>
        <label class="label" for="customer_name"
          >Customer name {{ !currentDealership.is_buyers_account ? "*" : "" }}</label
        >
        <input
          class="text-field margin-top-025 fix-placeholder-color"
          :required="!currentDealership.is_buyers_account"
          type="text"
          id="customer_name"
          v-model="dict.customer_name"
          placeholder="Name"
        />
        <label class="margin-top-075 label" for="customer_phone"
          >Customer phone {{ !currentDealership.is_buyers_account ? "*" : "" }}</label
        >
        <input
          class="text-field margin-top-025 fix-placeholder-color"
          :required="!currentDealership.is_buyers_account"
          type="tel"
          id="customer_phone"
          v-model="dict.customer_work_phone"
          placeholder="Phone"
        />
        <label class="margin-top-075 label" for="customer_email">Customer email</label>
        <input
          class="text-field margin-top-025 fix-placeholder-color"
          type="email"
          id="customer_email"
          v-model="dict.customer_email"
          placeholder="Email"
        />
      </div>
      <div class="flex-column">
        <p class="h5 no-margin margin-bottom-05 margin-top-2">Financing Details</p>
        <label class="label no-margin">Finance requested on proposed new purchase</label>
        <div class="flex-row margin-top-05">
          <input
            required
            class="margin-right-05 radio-field"
            type="radio"
            name="is_finance_requested"
            id="is_finance_requested"
            :value="true"
            v-model="dict.is_finance_requested"
          />
          <label class="body-2 vertical-flex-center" for="is_finance_requested">Yes</label>
        </div>
        <div class="flex-row margin-top-05">
          <input
            class="margin-right-05 radio-field"
            type="radio"
            name="is_finance_requested"
            id="not_is_finance_requested"
            :value="false"
            v-model="dict.is_finance_requested"
          />
          <label class="body-2 vertical-flex-center" for="not_is_finance_requested">No</label>
        </div>

        <label class="label no-margin margin-top">Appraised vehicle currently encumbered</label>
        <div class="flex-row margin-top-05">
          <input
            required
            class="margin-right-05 radio-field"
            type="radio"
            name="is_currently_encumbered"
            id="is_currently_encumbered"
            :value="true"
            v-model="dict.is_currently_encumbered"
          />
          <label class="body-2 vertical-flex-center" for="is_currently_encumbered">Yes</label>
        </div>
        <div class="flex-row margin-top-05">
          <input
            class="margin-right-05 radio-field"
            type="radio"
            name="is_currently_encumbered"
            id="not_is_currently_encumbered"
            :value="false"
            v-model="dict.is_currently_encumbered"
          />
          <label class="body-2 vertical-flex-center" for="not_is_currently_encumbered">No</label>
        </div>
        <div class="flex-column" v-if="dict.is_currently_encumbered">
          <label class="margin-top-075 label" for="approx_payout">Approx payout</label>
          <input
            class="margin-top-025 fix-placeholder-color text-field"
            type="number"
            id="approx_payout"
            v-model="dict.approx_payout"
            placeholder="Approx payout"
          />
          <label class="margin-top-075 label" for="monthly_repayment">Monthly payout</label>
          <input
            class="margin-top-025 fix-placeholder-color text-field"
            type="number"
            id="monthly_repayment"
            v-model="dict.monthly_repayment"
            placeholder="Monthly payout"
          />
          <label class="margin-top-075 label" for="financier">Financier</label>
          <input
            class="margin-top-025 fix-placeholder-color text-field"
            type="text"
            id="financier"
            v-model="dict.financier"
            placeholder="Financier"
          />
        </div>
      </div>
      <div class="flex-column">
        <p class="h5 no-margin margin-bottom-05 margin-top-2">Car Details</p>
        <label class="label no-margin" for="department"
          >Department {{ currentDealership.departments.length > 0 ? "*" : "" }}</label
        >
        <select
          class="dropdown-field margin-top-025"
          :required="currentDealership.departments.length > 0"
          v-model="dict.department"
        >
          <option value="">-</option>
          <option
            v-for="department in currentDealership.departments"
            :value="department.url"
            v-bind:key="department.url"
          >
            {{ department.title }}
          </option>
        </select>
        <label class="margin-top-075 label" for="inputMode">Car Lookup Mode</label>
        <div class="flex-column">
          <div class="flex-row margin-top-05">
            <input
              class="margin-right-05 radio-field"
              type="radio"
              name="inputMode"
              id="vinMode"
              value="0"
              v-model="inputMode"
            />
            <label class="body-2 vertical-flex-center" for="vinMode">VIN</label>
          </div>
          <div class="flex-row margin-top-05">
            <input
              class="margin-right-05 radio-field"
              type="radio"
              name="inputMode"
              id="regoMode"
              value="1"
              v-model="inputMode"
            />
            <label class="body-2 vertical-flex-center" for="regoMode">Rego</label>
          </div>
          <div class="flex-row margin-top-05">
            <input
              class="margin-right-05 radio-field"
              type="radio"
              name="inputMode"
              id="makeModelMode"
              value="2"
              v-model="inputMode"
            />
            <label class="body-2 vertical-flex-center" for="makeModelMode">Make/Model</label>
          </div>
        </div>
        <label class="margin-top label" for="registration">Registration Number</label>
        <input
          required
          class="text-field margin-top-025 fix-placeholder-color"
          placeholder="Registration"
          type="text"
          id="registration"
          v-model="dict.car_registration_number"
          :class="{ uppercase: dict.car_registration_number }"
        />
        <div class="flex-column" v-if="inputMode == 0">
          <label class="margin-top label" for="vin">VIN Number</label>
          <input
            :required="inputMode == 0"
            class="text-field margin-top-025 fix-placeholder-color"
            placeholder="VIN"
            type="text"
            id="vin"
            v-model="dict.car_chassis_number"
            :class="{ uppercase: dict.car_registration_number }"
          />
          <label class="margin-top label" for="year">Year</label>
          <input
            :required="inputMode == 0"
            class="text-field margin-top-025 fix-placeholder-color"
            placeholder="Model year"
            type="number"
            id="year"
            v-model="dict.car_model_year"
          />
        </div>
        <div class="flex-column" v-if="inputMode == 1 && currentDealership.country == 'AU'">
          <label class="margin-top label" for="state">State</label>
          <select
            :required="inputMode == 1"
            v-model="dict.car_registration_state"
            class="dropdown-field margin-top-025"
          >
            <option value="">-</option>
            <option value="SA">SA</option>
            <option value="NSW">NSW</option>
            <option value="VIC">VIC</option>
            <option value="QLD">QLD</option>
            <option value="WA">WA</option>
            <option value="NT">NT</option>
            <option value="TAS">TAS</option>
            <option value="ACT">ACT</option>
          </select>
        </div>
        <div class="flex-column" v-if="inputMode == 2">
          <label class="margin-top label" for="make">Make</label>
          <input
            :required="inputMode == 2"
            type="text"
            id="make"
            v-model="dict.car_make"
            class="text-field margin-top-025 fix-placeholder-color"
            placeholder="Make"
          />
          <label class="margin-top label" for="model">Model</label>
          <input
            :required="inputMode == 2"
            type="text"
            id="model"
            v-model="dict.car_model"
            class="text-field margin-top-025 fix-placeholder-color"
            placeholder="Model"
          />
        </div>
      </div>
      <primary-button
        class="margin-top"
        :title="creating ? 'Creating Appraisal...' : 'Create Appraisal'"
        type="submit"
      />
    </form>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { mapState, mapActions } from "vuex";
import { createForm } from "../../../api/Form.js";

export default {
  name: "NewFormModal",
  components: { PrimaryButton, BaseModalView },
  props: ["existingOffers"],
  data: function () {
    return {
      inputMode: 0,
      creating: false,
      dict: {
        source: null,
        customer_name: "",
        customer_work_phone: "",
        is_finance_requested: false,
        is_currently_encumbered: false,
        approx_payout: null,
        monthly_repayment: null,
        financier: "",
        department: "",
        car_registration_number: null,
        car_chassis_number: "",
        car_model_year: null,
        car_registration_state: "",
        car_make: null,
        car_model: null,
        dealer: null,
        is_ready_for_management: false
      }
    };
  },
  methods: {
    submitClicked: function () {
      if (this.creating) {
        return;
      }
      var endpoint = "";
      if (this.inputMode == 0) {
        endpoint = "create-from-vin/";
      } else if (this.inputMode == 1) {
        endpoint = "create-from-rego/";
      }
      this.creating = true;
      if (this.currentDealership.is_buyers_account) {
        if (!this.dict.customer_name) {
          this.dict.customer_name = "-";
        }
      }
      if (this.currentDealership.is_buyers_account) {
        if (!this.dict.source) {
          this.dict.source = "-";
        }
      }
      createForm(endpoint, this.dict)
        .then(response => {
          this.creating = false;
          this.$emit("formCreated", response);
        })
        .catch(error => {
          this.creating = false;
          this.addError(error);
        });
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  computed: {
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer,
      currentDealership: state => state.dealershipStore.currentDealership
    })
  },
  mounted: function () {
    this.dict.dealer = this.currentDealer.url;
    this.dict.is_ready_for_management = this.currentDealer.is_manager;
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
