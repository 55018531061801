<template>
  <base-modal-view title="Create Appraisals" :autoWidth="true" @cancel="cancelClicked">
    <div class="flex-column">
      <form @submit.prevent="submitClicked">
        <div class="flex-column">
          <div class="flex-row">
            <div v-if="uploadMode == 'vin'" class="flex-column flex-grow margin-right-05">
              <p class="label no-margin margin-bottom-025 margin-top">VIN number</p>
              <input
                required
                class="text-field"
                :class="{ uppercase: formDict.car_chassis_number }"
                type="text"
                v-model="formDict.car_chassis_number"
                placeholder="VIN"
              />
            </div>
            <div class="flex-column" :class="{ 'flex-grow': uploadMode == 'rego' }">
              <p class="label no-margin margin-bottom-025 margin-top">Car registration number</p>
              <input
                required
                class="text-field"
                :class="{ uppercase: formDict.car_registration_number }"
                type="text"
                v-model="formDict.car_registration_number"
                placeholder="Registration"
              />
            </div>
            <select
              v-if="uploadMode == 'rego'"
              required
              class="dropdown-field margin-left-025 margin-top-auto"
              v-model="formDict.car_registration_state"
            >
              <option value="">-- State --</option>
              <option value="SA">SA</option>
              <option value="NSW">NSW</option>
              <option value="VIC">VIC</option>
              <option value="QLD">QLD</option>
              <option value="WA">WA</option>
              <option value="NT">NT</option>
              <option value="TAS">TAS</option>
              <option value="ACT">ACT</option>
            </select>
          </div>
          <div class="flex-row">
            <div class="flex-column margin-top-05">
              <label class="body-2 margin-bottom-05">Optional Extras</label>
              <div class="flex-row">
                <div class="flex-column flex-grow">
                  <label class="label margin-bottom-025">Mileage</label>
                  <input
                    class="text-field flex-grow margin-right-05"
                    type="number"
                    placeholder="Mileage"
                    v-model="formDict.car_mileage"
                  />
                </div>
                <div class="flex-column flex-grow">
                  <label class="label margin-bottom-025">Stock Number</label>
                  <input
                    class="text-field flex-grow margin-right-05"
                    :class="{ uppercase: formDict.car_stock_number }"
                    type="text"
                    placeholder="Stock Number"
                    v-model="formDict.car_stock_number"
                  />
                </div>
                <div class="flex-column flex-grow">
                  <label class="label margin-bottom-025">Build Date</label>
                  <input
                    class="text-field flex-grow margin-right-05"
                    type="date"
                    placeholder="Build Date"
                    v-model="formDict.car_build_date"
                  />
                </div>
                <div class="flex-column flex-grow">
                  <label class="label margin-bottom-025">Department</label>
                  <select
                    class="text-field flex-grow margin-right-05"
                    v-model="formDict.department"
                    v-if="currentDealership.departments"
                  >
                    <option value="">-- Select a Department --</option>
                    <option
                      v-for="department in currentDealership.departments"
                      v-bind:key="department.url"
                      :value="department.url"
                    >
                      {{ department.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <primary-button :disabled="!validation()" class="margin-top-05" title="Create Appraisal" />
        </div>
      </form>
      <table class="results-table margin-top" v-if="forms.length > 0">
        <tr class="header">
          <th v-if="uploadMode == 'vin'">VIN Number</th>
          <th>Rego</th>
          <th v-if="uploadMode == 'rego'">State</th>
          <th>Make</th>
          <th>Model</th>
          <th>Created</th>
        </tr>
        <tr class="data-row" v-for="form in forms" v-bind:key="form">
          <td v-if="uploadMode == 'vin'">{{ form.car_chassis_number }}</td>
          <td class="uppercase">{{ form.car_registration_number }}</td>
          <td v-if="uploadMode == 'rego'">{{ form.car_registration_state }}</td>
          <td>{{ form.car_make }}</td>
          <td>{{ form.car_model }}</td>
          <td>{{ form.created == 0 ? "Creating..." : form.created == 1 ? "Created" : "Failed" }}</td>
        </tr>
      </table>
    </div>
  </base-modal-view>
</template>

<script>
import PrimaryButton from "../../Buttons/v2/PrimaryButton.vue";
import BaseModalView from "./BaseModalView.vue";
import { createForm } from "../../../api/Form.js";
import _ from "lodash";
import { mapActions, mapState } from "vuex";

export default {
  name: "CreateAppraisalsFromRegoListModal",
  components: { PrimaryButton, BaseModalView },
  props: {
    uploadMode: {
      type: String,
      default: "rego"
    }
  },
  data: function () {
    return {
      formDict: {
        car_registration_number: "",
        car_registration_state: "",
        source: "Sighted",
        customer_name: "-",
        customer_work_phone: "",
        car_chassis_number: "",
        car_model_year: null,
        car_make: "",
        car_model: "",
        car_mileage: "",
        car_stock_number: "",
        car_build_date: null,
        dealer: null,
        is_ready_for_management: true,
        department: null,
        created: 0
      },
      forms: []
    };
  },
  methods: {
    submitClicked: function () {
      var form = _.cloneDeep(this.formDict);
      let index = this.forms.length;
      if (!form.car_build_date) {
        delete form["car_build_date"];
      }
      if (!form.car_mileage) {
        delete form["car_mileage"];
      }
      this.forms.push(form);
      this.formDict.car_registration_number = "";
      this.formDict.car_chassis_number = "";
      this.formDict.car_mileage = "";
      this.formDict.car_stock_number = "";
      this.formDict.car_build_date = null;

      createForm("create-from-" + this.uploadMode + "/", form)
        .then(response => {
          this.forms[index].created = 1;
          this.forms[index].car_make = response.car_make;
          this.forms[index].car_model = response.car_model;
          this.$emit("formCreated", response);
        })
        .catch(error => {
          this.forms[index].created = 2;
          console.log(error);
        });
    },
    validation: function () {
      if (this.uploadMode == "rego") {
        return this.formDict.car_registration_number && this.formDict.car_registration_state;
      } else if (this.uploadMode == "vin") {
        return this.formDict.car_registration_number && this.formDict.car_chassis_number;
      }
      return false;
    },
    cancelClicked: function () {
      this.$emit("cancel");
    },
    inputError: function () {
      return false;
    },
    ...mapActions({
      addError: "errorStore/addError"
    })
  },
  computed: {
    ...mapState({
      currentDealer: state => state.dealershipStore.currentDealer,
      currentDealership: state => state.dealershipStore.currentDealership
    })
  },
  mounted: function () {
    this.formDict.dealer = this.currentDealer.url;
    this.formDict.is_ready_for_management = this.currentDealer.is_manager;
    if (this.currentDealership.departments) {
      if (this.currentDealership.departments.length == 1) {
        this.formDict.department = this.currentDealership.departments[0].url;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/v2/base.scss";
</style>
